import React from "react"
import IconSeats from "@svgs/seats.svg"
import IconDimensions from "@svgs/dimensions.svg"
import { Variants, getPricing } from "@context/productContext"

const transformProductToCard = datoCmsProduct => {
  const {
    id,
    name,
    brand,
    price,
    size,
    usage,
    netoProduct,
    netoVariants,
    path,
    specsImage,
    heroImage,
    viewer,
    clearliftCompatible,
    infrared,
    freeDelivery,
    motorised
  } = datoCmsProduct
  const pricing = getPricing(netoProduct)

  const dimensions = []

  netoProduct?.seats &&
    dimensions.push({
      name: "Seating",
      icon: <IconSeats />,
      label: `${netoProduct.seats} Adults`
    })

  netoProduct?.dimensions &&
    dimensions.push({
      name: "Dimensions",
      icon: <IconDimensions />,
      label: `${netoProduct.dimensions}`
    })

  return {
    hover: true,
    elevated: true,
    padImage: true,
    id,
    heading: name,
    brand,
    sku: netoProduct?.sku,
    price,
    size,
    usage,
    pricing: pricing,
    dimensions,
    finance: netoProduct.finance,
    length: netoProduct.length,
    width: netoProduct.width,
    link: {
      path,
      title: "More"
    },
    image: specsImage ? specsImage : heroImage,
    hoverImage: specsImage ? heroImage : null,
    onSale: pricing.onSale,
    netoVariants,
    viewer,
    freeDelivery,
    clearliftCompatible,
    infrared,
    motorised
  }
}

const transformShipping = netoShipping => {
  const pricing = getPricing(netoShipping)

  return {
    ...netoShipping,
    prices: pricing,
    type: `shipping`
  }
}

const removeJetDuplicates = array =>
  Array.from(new Set(array.map(a => a.jetType?.name))).map(name => {
    return array.find(a => a.jetType?.name === name)
  })

const getJetCarouselItems = jetLayouts => {
  const allJets = jetLayouts.map(layout => layout.jetList).flat()
  const uniqueJets = removeJetDuplicates(allJets).filter(
    ({ jetType }) => jetType?.showInCarousel
  )

  const collator = new Intl.Collator("en", {
    numeric: true,
    sensitivity: "base"
  })
  const sortedJets = uniqueJets.sort((a, b) =>
    collator.compare(b.jetType.name, a.jetType.name)
  )
  return transfromJetTypesToCarousel(sortedJets)
}

const transfromJetTypesToCarousel = jetTypes => {
  const validJetTypes = jetTypes.filter(({ jetType }) => {
    if (!jetType) return false

    try {
      const { name, descriptionNode, image } = jetType

      if (!name || !descriptionNode || !image) {
        return false
      }
    } catch (e) {
      console.log("Could not make jet slide: ", e)
      return false
    }

    return true
  })

  const slides = validJetTypes.map(({ id, jetType }) => ({
    id,
    heading: "Jet Types",
    subHeading: jetType?.name || "",
    caption: jetType?.descriptionNode.childMarkdownRemark.html,
    defaultImage: jetType?.image
  }))

  return slides
}

const transformProductToSpecial = (
  {
    datoProduct,
    name,
    brand,
    description,
    id,
    dimensions,
    width,
    length,
    sku,
    seats,
    saveUpTo,
    rrp,
    retailPrice,
    clearanceNote,
    clearanceUrl,
    promotionStartDate,
    promotionPrice,
    promotionExpiryDate,
    financeType,
    specifics,
    warehouseData
  },
  placeholderImage
) => {
  const LABELS = [
    "Shell Colour",
    "Cabinet Colour",
    "Condition",
    "Entertainment",
    "Canopy Colour"
  ]

  const pricing = getPricing({
    saveUpTo,
    rrp,
    retailPrice,
    promotionStartDate,
    promotionPrice,
    promotionExpiryDate,
    financeType
  })

  const details = []

  LABELS.forEach(label => {
    const spec = specifics.find(detail => detail.name === label)
    if (spec !== undefined) details.push(spec)
  })

  sku && details.push({ name: "Serial", value: sku })
  description &&
    details.splice(1, 0, { name: "Description", value: description })
  clearanceNote && details.push({ name: "Note", value: clearanceNote })

  const productState = warehouseData?.WarehouseState
  const productPostCode = warehouseData?.WarehousePostcode

  const hasPartialLocation =
    (productState || productPostCode) !== ("" || null || undefined)

  let warehouseString

  if (productState !== "" && productState !== undefined) {
    warehouseString = productState
  }

  if (productPostCode !== "" && productPostCode !== undefined) {
    if (productState !== "" && productPostCode !== undefined) {
      warehouseString += `, ${productPostCode}`
    } else {
      warehouseString = productPostCode
    }
  }

  hasPartialLocation &&
    details.splice(0, 0, {
      name: "Location",
      value: warehouseString
    })

  // Common fallback values
  const config = {
    heading: name,
    brand,
    image: placeholderImage,
    links: [],
    dimensions: []
  }

  if (seats && dimensions) {
    config.dimensions = [
      {
        name: "Seating",
        icon: <IconSeats />,
        label: `${seats} Adults`
      },
      {
        name: "Dimensions",
        icon: <IconDimensions />,
        label: `${dimensions}`
      }
    ]
  }

  if (datoProduct) {
    const { specsImage, heroImage, netoProduct } = datoProduct
    config.brand = datoProduct.brand
    config.image = specsImage ? specsImage : heroImage
    config.hoverImage = specsImage ? heroImage : null

    if (netoProduct && netoProduct.seats && netoProduct.dimensions) {
      config.dimensions = [
        {
          name: "Seating",
          icon: <IconSeats />,
          label: `${netoProduct.seats} Adults`
        },
        {
          name: "Dimensions",
          icon: <IconDimensions />,
          label: `${netoProduct.dimensions}`
        }
      ]
    }
  }

  config.links.push({
    title: "Get a Quote",
    to: `/contact-us/specials-enquiry`,
    state: {
      heading: config.heading,
      image: config.image,
      hoverImage: config.hoverImage,
      brand: config.brand,
      details: details,
      price: pricing.price,
      sku: sku
    }
  })

  return {
    hover: true,
    elevated: true,
    padImage: true,
    imageDisclaimer: true,
    extended: true,
    special: true,
    id: id,
    pricing: pricing,
    sku: sku,
    length: length,
    width: width,
    details: details,
    clearanceUrl: clearanceUrl,
    state: productState,
    ...config
  }
}

const gtmCategory = usage => {
  const categoryArray = []
  usage?.forEach(category => {
    categoryArray.push(category.name)
  })
  const categoryString = categoryArray.join("|")
  return categoryString
}

export {
  transformProductToCard,
  transformShipping,
  removeJetDuplicates,
  getJetCarouselItems,
  transformProductToSpecial,
  gtmCategory
}
